<template>
  <v-container style="background-color: white;" class="ma-0 pa-0">
    <CurriculumComponent ref="html2pdf"/>
    <v-row class="d-flex justify-center mt-12 mb-3">
      <v-btn rounded dark color="#22234a" class="my-text-button" x-large @click="downloadPdf()">Descargar CV
        <v-icon
            class="ml-2 " color="white">mdi-tray-arrow-down
        </v-icon>
      </v-btn>
    </v-row>
    <ChatComponent />
    <div>
      <vue-html2pdf ref="html2pdf" :show-layout="false" :float-layout="true" :enable-download="true"
                    :preview-modal="false"
                    :paginate-elements-by-height="1200" filename="cv" :pdf-quality="2" :manual-pagination="true"
                    pdf-format="legal"
                    pdf-orientation="portrait" pdf-content-width="815px">
        <section slot="pdf-content">
          <v-app>
            <!--            <v-main class="pa-0 " >-->
            <!--              <v-container >-->
            <section class="pdf-item">
              <CurriculumComponentPDF class="cv-pdf"/>
            </section>
            <!-- <div class="html2pdf__page-break" /> -->
            <!--              </v-container>-->
            <!--            </v-main>-->
          </v-app>
        </section>
      </vue-html2pdf>


    </div>
  </v-container>
</template>

<script>
import CurriculumComponent from '../../components/CurriculumComponent.vue';
import CurriculumComponentPDF from '../../components/CurriculumComponentPDF.vue';

import AppFooter from '../../components/AppFooter.vue';
import ChatComponent from "../../components/ChatComponent.vue";
import {mapState} from 'vuex';


export default {
  components: {
    CurriculumComponent,
    CurriculumComponentPDF,
    AppFooter,
    ChatComponent
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(["token"]),
  },
  methods: {
    downloadPdf() {
      this.$refs.html2pdf.generatePdf();
    },

  }
};
</script>

<style lang="scss" scoped>
.cv-pdf {
  position: relative;
  height: 100% !important;

  ::v-deep .pdfCard--style {
    height: 1300px !important;
    max-height: 1300px !important;

  }

  ::v-deep .text-size--pdf {
    font-size: 20px !important;
  }

  ::v-deep .items--style {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
}

.card {
  background-color: #f5f5f5;
  border-radius: 25px;
}

.bg-primary-color {
  color: #22234a;
}

.my-button:hover {
  background-color: #333458;
  color: white;
}

.pdf-item {
  display: flex;
  position: relative;
  height: 1340px !important;
  max-height: 1340px !important;
  padding: 20px;
}


</style>
