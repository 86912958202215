<template>
  <v-container class="pa-0 justify-center d-flex" fill-height fluid>
    <v-row id="cv" :class="dinamicClass">
      <v-col cols="12" sm="12" md="5" class="d-flex justify-center">
        <v-card :color="colorChange" class="rounded-xl pdfCard--style" max-width="465" elevation="0">
          <div class="mt-12 d-flex justify-center" v-if="userInfo.ruta_foto">
            <v-avatar size="250">
              <v-img
                  :src="userInfo.ruta_foto"
                  height="250"
                  max-width="250"
              ></v-img>
            </v-avatar>
          </div>
          <div class="mt-12 d-flex justify-center" v-else>
            <v-avatar size="250">
              <img :src="require(`@/assets/img/default_user.png`)" height="250" width="250"/>
            </v-avatar>
          </div>
          <div class="d-flex justify-center mt-7 blueDarkrrhh--text">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle" class="text-capitalize">
                      {{ nombresChange }}
                      <br>
                      {{ apellidoChange }}
                      <v-divider></v-divider>
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle" class="text-center font-weight-bold text-capitalize">
                  {{ nombresChange }}
                  {{ apellidoChange }}
                  <v-divider></v-divider>
              </span>
            </template>
          </div>
          <!-- <v-divider></v-divider> -->
          <!-- <span class="d-flex justify-center mt-3">TECNICO INFORMATICO</span> -->
          <div class="d-flex blueDarkrrhh--text" :class="titleChange" style="margin-top: 100px;">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle">CONTACTO</span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle">CONTACTO</span>
            </template>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-3" :class="$vuetify.breakpoint.xs ? 'ml-10' : ''">
              <v-col cols="12" class="pb-0">
                <div v-if="emailChange">
                  <v-icon class="ml-5">mdi-phone</v-icon> <span class="ml-5"> {{ formatPhoneNumber(userInfo?.telefono) }} </span>
                </div>
                <div v-else>
                  <v-icon class="ml-0">mdi-phone</v-icon> <span class="ml-5"> {{ formatPhoneNumber(userInfo?.telefono) }} </span>
                </div>
              </v-col>
              <v-col cols="12" class="pb-0">
                <div v-if="emailChange">
                  <v-icon class="ml-6">mdi-email-outline</v-icon><span class="ml-5">{{userInfo?.email_personal}}</span>
                </div>
                <div v-else>
                  <v-icon class="ml-1">mdi-email-outline</v-icon><span class="ml-5">{{userInfo?.email_personal}}</span>
                </div>
              </v-col>
          </v-row>
          <div class=" d-flex blueDarkrrhh--text" :class="titleChange" style="margin-top: 50px;">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle" style="margin-top: 40px;"> SOBRE MÍ</span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle" style="margin-top: 40px;"> SOBRE MÍ</span>
            </template>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-center flex-column ml-5 mr-5 mt-4" v-html="datosPersona?.descripcion"></div>
        </v-card>
      </v-col>
      <!-- Segunda parte -->
      <v-col cols="12" sm="12" md="7">
        <div class="d-flex bluerrhh--text" :class="titleChange">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-row class="text-center">
              <v-col cols="12">
                <span :class="dinamicClassTitle" class="text-size--pdf">FORMACIÓN</span>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <span :class="dinamicClassTitle" class="text-size--pdf">FORMACIÓN</span>
          </template>
        </div>
        <v-divider></v-divider>
        <v-row class="mt-3" v-for="(formacionPesona, i) in datosPersona?.formacion" :key="i"
               v-if="formacionPesona?.TipoFormacion?.nombre == 'Formacion continua'">
          <v-col cols="2" md="1" class="items--style d-flex justify-end">
            <v-icon class="ml-2 mr-2">mdi-check</v-icon>
          </v-col>
          <v-col cols="9" class="ml-2 items--style">{{ formacionPesona?.FormacionEducativa?.nombre }} |
            {{ moment(formacionPesona.fecha_inicio).format('YYYY') }} -
            {{ formacionPesona.estudia == true ? 'Actualmente' : moment(formacionPesona.fecha_fin).format('YYYY') }}
            <br>
            {{ formacionPesona?.nombre_institucion }}
          </v-col>
        </v-row>
        <div class="d-flex mt-12 bluerrhh--text" :class="titleChange">
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-row class="text-center">
              <v-col cols="12">
                <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">EXPERIENCIA LABORAL</span>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">EXPERIENCIA LABORAL</span>
          </template>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-3" v-for="(experiaPersona, i) in datosPersona?.experiencia" :key="'A'+ i">
              <v-col cols="2" md="1" class="items--style  d-flex justify-end">
                  <v-icon class="ml-2">mdi-check</v-icon>
              </v-col>
              <v-col cols="9" class="items--style">{{experiaPersona?.puesto}} |  {{ moment(experiaPersona?.fecha_inicio).format('YYYY')}}  - {{ experiaPersona.lugar_trabajo == true ? 'Actualmente':  moment(experiaPersona?.fecha_fin,'YYYY').format('YYYY') }} <br>{{experiaPersona?.lugar_trabajo}}</v-col>
          </v-row>
          <div class="d-flex mt-12 bluerrhh--text" :class="titleChange">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">CERTIFICADOS Y DIPLOMAS</span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">CERTIFICADOS Y DIPLOMAS</span>
            </template>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-3" v-for="(formacionPesona, i) in datosPersona?.formacion" :key="i" v-if="formacionPesona?.TipoFormacion?.nombre == 'Certificados'">
              <v-col cols="2" md="1" class="items--style  d-flex justify-end">
                  <v-icon class="ml-2">mdi-check</v-icon>
              </v-col>
              <v-col cols="9" class="items--style">{{ formacionPesona?.otro_titulo }} | {{moment(formacionPesona.fecha_inicio).format('YYYY')}} - {{formacionPesona.estudia== true ? 'Actualmente': moment(formacionPesona.fecha_fin).format('YYYY') }} <br>{{formacionPesona?.nombre_institucion}}</v-col>
          </v-row>
          <div class="d-flex mt-12 bluerrhh--text" :class="titleChange">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">CONOCIMIENTOS</span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">CONOCIMIENTOS</span>
            </template>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-3"  v-for="(ConocimientoPersona, i) in datosPersona?.conocimiento" :key="'b'+ i">
              <v-col cols="2" md="1" class="items--style  d-flex justify-end">
                  <v-icon class="ml-2">mdi-check</v-icon>
              </v-col>
              <v-col cols="9" class="items--style">{{ConocimientoPersona?.nombre_conocimiento}}</v-col>
          </v-row>
          <div class="d-flex mt-12 bluerrhh--text" :class="titleChange">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">CAPACIDADES</span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">CAPACIDADES</span>
            </template>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-3" v-for="(CapacidadPersona, i) in datosPersona?.capacidade" :key="'c'+ i">
              <v-col cols="2" md="1" class="items--style  d-flex justify-end">
                  <v-icon class="ml-2">mdi-check</v-icon>
              </v-col>
              <v-col cols="9" class="items--style">{{CapacidadPersona.nombre}}</v-col>
          </v-row>
          <div class="d-flex mt-12 bluerrhh--text" :class="titleChange">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-row class="text-center">
                <v-col cols="12">
                  <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">OTROS ASPECTOS</span>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <span :class="dinamicClassTitle" class="text-size--pdf d-inline-block" style="word-break: break-all;">OTROS ASPECTOS</span>
            </template>
          </div>
          <v-divider></v-divider>
          <v-row class="mt-3" v-for="(item,i) in datosPersona?.otros_aspectos" :key="'d'+i">
              <v-col cols="2" md="1" class="items--style  d-flex justify-end">
                  <v-icon class="ml-2">mdi-check</v-icon>
              </v-col>
              <v-col cols="9" class="items--style">{{ item?.nombre }}</v-col>
          </v-row>
          <!-- <v-row class="mt-2">
              <v-col cols="1">
                  <v-icon class="ml-2">mdi-check</v-icon>
              </v-col>
              <v-col cols="9">Ética de trabajo</v-col>
          </v-row> -->
      </v-col>
    </v-row>

    <!-- <v-row class="d-flex justify-center mt-12 mb-3">
    <v-btn rounded dark color="#22234a" x-large @click="DescargarCV()">Descargar CV <v-icon class="ml-2 " color="white">mdi-tray-arrow-down</v-icon></v-btn>
  </v-row> -->
    <app-loader v-if="loading"></app-loader>
  </v-container>
</template>

<script>

import AppLoader from "@/components/AppLoader.vue";
import {mapState} from "vuex";

const html2pdf = require('html2pdf.js');


export default {
  components: {
    AppLoader
  },

  props: {
    menu: {
      type: Array,
      default: () => {
        return [{uri: "/", nombre: "Inicio", icono: "mdi-home"}];
      },
    },
  },
  data: () => ({

    datosPersona: null,
    loading: false,

  }),
  methods: {

    async cargarDatos() {
      if (this.isTokenValido(this.token)) {
        try {
          if(typeof this.userInfo.id_persona !== 'undefined'){
            const response = await this.services.datosPersona.getDatosCv(this.userInfo.id_persona);
            if (response.status == 200) {
              this.datosPersona = response.data
              //console.log("datos cv",this.datosPersona)
            }
          }
        } catch (e) {

        } finally {

        }

      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }

    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return '';
      // Verifica si el número ya tiene el formato xxxx-xxxx
      if (phoneNumber.length === 9 && phoneNumber[4] === '-') {
        return phoneNumber;
      }
      // Formatea el número si no tiene el formato xxxx-xxxx
      return phoneNumber.slice(0, 4) + '-' + phoneNumber.slice(4);
    },


  },
  computed: {
    ...mapState({
      userInfo: "userInfo",
      token: "token",
    }),

    colorChange() {
      return this.$vuetify.breakpoint.smAndDown ? '#ffff' : '#f6f6f6';
    },

    titleChange() {
      return this.$vuetify.breakpoint.smAndDown ? this.$vuetify.breakpoint.xs ? 'justify-left' : 'justify-left' : 'justify-center';
    },

    userChange() {
      return this.userInfo?.email_personal.split("@")[0];
    },

    domainChange() {
      return `@${this.userInfo?.email_personal.split("@")[1]}`;
    },

    emailChange() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },

    nombresChange(){
      let a_n = this.userInfo.nombres.split(" ")

      // Filtrar elementos vacíos y 'null'
      let miArrayFiltrado = a_n.filter(elemento => elemento !== '' && elemento !== 'null');

      // Capitalizar la primera letra de cada palabra y unir en un solo string
      let resultadoFinal = miArrayFiltrado.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

      // Eliminar espacios en blanco al inicio y al final
      resultadoFinal = resultadoFinal.trim();

      return resultadoFinal
    },
    apellidoChange(){
      let a_a = this.userInfo.apellidos.split(" ")

      // Filtrar elementos vacíos y 'null'
      let miArrayFiltrado = a_a.filter(elemento => elemento !== '' && elemento !== 'null');

      // Capitalizar la primera letra de cada palabra y unir en un solo string
      let resultadoFinal = miArrayFiltrado.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

      // Eliminar espacios en blanco al inicio y al final
      resultadoFinal = resultadoFinal.trim();

      return resultadoFinal
    },
//     async DescargarCV() {
//   const options = {
//     margin: { top: 20, right: 10, bottom: 20, left: 10 },
//     filename: 'mi-archivo.pdf',
//     image: { type: 'jpeg', quality: 0.98 },
//     jsPDF: { unit: 'mm', format: 'A4', orientation: 'portrait' },
//   };

//   const content = this.$refs.contentToPrint;

//   try {
//     const canvas = await html2canvas(content);
//     const imageData = canvas.toDataURL('image/jpeg', options.image.quality);

//     const pdf = new jsPDF(options.jsPDF);
//     const pdfWidth = pdf.internal.pageSize.getWidth(); // Obtener el ancho de la página A4
//     const pdfHeight = pdf.internal.pageSize.getHeight(); // Obtener el alto de la página A4
//     pdf.addImage(imageData, 'JPEG', options.margin.left, options.margin.top, pdfWidth - options.margin.left - options.margin.right, pdfHeight - options.margin.top - options.margin.bottom);

//     pdf.save(options.filename);
//   } catch (error) {
//     console.error('Error al generar el PDF:', error);
//   }
// }

  dinamicClass() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return '';
      case "sm":
        return 'ml-10';
      case "md":
        return 'ml-10';
      case "lg":
        return 'ml-10';
      case "xl":
        return 'ml-10';
      case "2xl":
        return 'ml-10';
    }
  },
  dinamicClassTitle() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5 mb-4";
        case "sm":
          return "text-h5 mb-4";
        case "md":
          return "text-h4 font-weight-bold";
        case "lg":
          return "text-h4 font-weight-bold";
        case "xl":
          return "text-h4 font-weight-bold";
        case "2xl":
          return "text-h4 font-weight-bold";
      }
    },
  },
  created() {
    this.loading = true;
    setTimeout(async () => {
      await this.cargarDatos();
      this.loading = false;
    }, 2000);
  },


};


</script>

<style>

</style>
